export const imageHelper = {
    fetchFromInput
};

function fetchFromInput(input) {
    return new Promise((resolve, reject) => {
        const image = input.target;
        const files = image.files;

        if (files.length > 0) {
            const file = files[0]
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const dataUrl = event.target.result;
                const extension = dataUrl.split(';')[0].split('/')[1];
                resolve({ file: file, dataUrl: dataUrl, extension: extension });
            };

            reader.onerror = (error) => {
                reject(error);
            };
        } else {
            resolve(null);
        }

        image.value = '';
    });
}