<script>
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {routerHelper} from "@/helpers/router-helper";
import shopCreateCategoryModal from "../shop/category/shop-create-category-modal.vue";
import {swalHelper} from "@/helpers/swal-helper";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";

export default {
  components: {
    CustomCardSubtitle,
    shopCreateCategoryModal,
    PageHeader
  },

  props: {
    shopId: {
      type: String
    }
  },

  data() {
    return {
      shopCategories: null,
      allCategories: null,

      checkedCategories: [],
      shop: {}
    };
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('shop-categories.title', {'name': this.shop.name}),
          active: true
        }
      ]
    },

    async loadCategoriesWithoutParents() {
      try {
        const {data} = await axios.get(`/shop/${this.shop.id}/category`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.shopCategories = data
      } catch (error) {
        // ignored
      }
    },

    async loadParentCategory(clickedCategory) {
      if (this.checkedCategories.includes(clickedCategory.id)) {
        return;
      }

      this.checkedCategories.push(clickedCategory.id)

      try {
        const {data} = await axios.get(`/shop/${this.shop.id}/category/parent/${clickedCategory.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        })

        for (const parentCategory of data) {
          if (parentCategory.parentId !== null) {
            if (clickedCategory.id === parentCategory.parentId) {
              if (!clickedCategory.categories) {
                clickedCategory.categories = [];
              }

              clickedCategory.categories = clickedCategory.categories.filter(element => element.id !== parentCategory.id)
              clickedCategory.categories.push(parentCategory)
            }
          }
        }

        this.$root.$emit('refresh-tree')
      } catch (ignored) {
        // ignored
      }
    },

    editCategory(clickedCategory) {
      const category = Object.assign({}, clickedCategory);
      delete category.categories

      this.$refs.shopCreateCategoryModal.openModal(this.shop, category, () => setTimeout(() => this.$router.go(this.$router.currentRoute), 350))
    },

    deleteCategory(category) {
      swalHelper.yesOrNo(() => {
        axios.delete(`/shop/${this.shop.id}/category`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {"id": category.id}
        }).then(() => {
          this.$bvToast.toast(this.$t('categories.delete.success'), {
            title: this.$t('message.success'),
            variant: 'success',
            solid: true
          });

          setTimeout(() => this.$router.go(this.$router.currentRoute), 350)
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        })
      });
    },

    async loadShop(id) {
      const {data} = await axios.get(`/shop/${id}`, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.shop = data
    }

  },

  computed: {

    getShopCategories() {
      return this.shopCategories;
    },

    getSelectedShopId() {
      try {
        const params = routerHelper.params();
        const shopId = params.shopId

        if (shopId) {
          return shopId
        }
      } catch (error) {
        // ignore
      }

      if (this.shopId) {
        return this.shopId
      }

      return this.$store ? this.$store.state.shop.shopId : '';
    }

  },

  async created() {
    try {
      await this.loadShop(this.getSelectedShopId)
      await this.loadCategoriesWithoutParents()
    } catch (error) {
      console.log(error)
    }
  },

  mounted() {
    this.$root.$on('ecat-click-category', async (clickedCategory) => this.loadParentCategory(clickedCategory))
    this.$root.$on('ecat-click-edit', (clickedCategory) => this.editCategory(clickedCategory))
    this.$root.$on("ecat-click-delete", (clickedCategory) => this.deleteCategory(clickedCategory))
  },

  beforeDestroy() {
    this.$root.$off("ecat-click-category");
    this.$root.$off("ecat-click-edit");
    this.$root.$off("ecat-click-delete");
  }

};
</script>

<template>
  <div>
    <PageHeader :title="$t('shop-categories.title', { 'name': shop.name })" :items="getItems()"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">
                <a class="btn btn-success" @click="$refs.shopCreateCategoryModal.openModal(shop)">
                  <i class="mdi mdi-plus mr-2"></i> {{ $t('categories.create.button') }}
                </a>
              </div>
            </div>

            <template v-if="shopCategories">
              <template v-if="shopCategories.length !== 0">
                <div class="py-3">
                  <category-parent class="tree" :trees="getShopCategories"></category-parent>
                </div>
              </template>
              <template v-else>
                <h5 class="text-center py-3">{{ $t('message.no-elements') }}</h5>
              </template>
            </template>
            <template v-else>
              <div class="text-center py-3">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <shop-create-category-modal ref="shopCreateCategoryModal"/>
  </div>
</template>

<style>
.tree {
  --spacing: 1.5rem;
  --radius: 10px;
}

.tree li {
  display: block;
  position: relative;
  padding-left: calc(2 * var(--spacing) - var(--radius) - 2px);
}

.tree ul {
  margin-left: calc(var(--radius) - var(--spacing));
  padding-left: 0;
}

.tree ul li {
  border-left: 2px solid #ddd;
}

.tree ul li:last-child {
  border-color: transparent;
}

.tree ul li::before {
  content: '';
  display: block;
  position: absolute;
  top: calc(var(--spacing) / -2);
  left: -2px;
  width: calc(var(--spacing) + 2px);
  height: calc(var(--spacing) + 1px);
  border: solid #ddd;
  border-width: 0 0 2px 2px;
}

.tree summary {
  display: block;
  cursor: pointer;
}

.tree summary::marker,
.tree summary::-webkit-details-marker {
  display: none;
}

.tree summary:focus {
  outline: none;
}

.tree summary:focus-visible {
  outline: 1px dotted #000;
}

.tree li::after,
.tree summary::before {
  content: '';
  display: block;
  position: absolute;
  top: calc(var(--spacing) / 2 - var(--radius));
  left: calc(var(--spacing) - var(--radius) - 1px);
  width: calc(2 * var(--radius));
  height: calc(2 * var(--radius));
  border-radius: 50%;
  background: #ddd;
}

.tree summary::before {
  z-index: 1;
  background: #696 url('../../assets/images/expand-collapse.svg') 0 0;
}

.tree details[open] > summary::before {
  background-position: calc(-2 * var(--radius)) 0;
}
</style>